import { Grow } from '@mui/material';
import React from 'react';
import Brain from '../../images/think-zambia-brain.png';
import Text from '../../images/think-zambia-text.png';

export default function AnimatedLogo() {
  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <div style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 1,
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'center',
      }}
      />
      <div style={{
        position: 'absolute',
        width: '100%',
      }}
      >
        <Grow in timeout={4000}>
          <div style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          >
            <img
              alt="Think Zambia Brain"
              src={Brain}
              style={{
                width: 250,
              }}
            />
          </div>
        </Grow>
      </div>
      <div style={{
        position: 'relative',
      }}
      >
        <Grow in timeout={1000}>
          <div style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          >
            <img
              alt="Think Zambia Logo"
              src={Text}
              style={{
                width: 250,
              }}
            />
          </div>
        </Grow>
      </div>
    </div>
  );
}
