import {
  CardContent,
  Container,
  Grid,
  IconButton,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SvgButton from './SvgButton';
import { useThinkZambia } from '../context';
import HourOfCodeActivityCard from '../images/hour-of-code-activity-card.jpg';

function DigitalLiteracy() {
  const {
    leftDrawerOpen,
    setLeftDrawerOpen,
  } = useThinkZambia();

  return (
    <SwipeableDrawer
      anchor="left"
      open={leftDrawerOpen}
      onClose={() => setLeftDrawerOpen(false)}
      onOpen={() => setLeftDrawerOpen(true)}
      transitionDuration={{
        enter: 1000,
        exit: 300,
      }}
    >
      <div
        style={{
          width: '100vw',
          minHeight: '95vh',
          backgroundColor: '#C7A567',
        }}
      >
        <div style={{
          height: '100vh',
          display: 'flex',
          placeContent: 'center flex-start',
          alignItems: 'center',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'flex-start',
          position: 'fixed',
          left: '1em',
        }}
        >
          <IconButton onClick={() => setLeftDrawerOpen(false)}>
            <ChevronLeftIcon
              fontSize="large"
              style={{
                color: '#ffffff',
                fontSize: '4rem',
              }}
            />
          </IconButton>
        </div>
        <div style={{
          height: '100vh',
          display: 'flex',
          placeContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'center',
        }}
        >
          <Container>
            <CardContent>
              <Typography
                variant="h1"
                style={{
                  color: '#ffffff',
                }}
              >
                Foster Digital Literacy
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="subtitle1">
                Our Digital Literacy program acts by providing access to our modern computer
                centers to spark a genuine interest in technological solutions and nurture
                creativity through gamified learning and problem-solving.
              </Typography>
            </CardContent>
          </Container>
        </div>
      </div>
      <div
        style={{
          width: '100vw',
          minHeight: '95vh',
        }}
      >
        <Grid container>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <div style={{
              height: '100%',
            }}
            >
              <img
                style={{
                  height: '100%',
                  width: '100%',
                }}
                src={HourOfCodeActivityCard}
                alt="hour of code activity"
              />
            </div>
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <div style={{
              minHeight: '95vh',
              display: 'flex',
              placeContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              alignContent: 'center',
              justifyContent: 'center',
            }}
            >
              <Container maxWidth="sm">
                <CardContent>
                  <Typography variant="h2">
                    Digital Literacy for everyone
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant="subtitle2">
                    Instruction in youth is like engraving in stone, as the saying goes.
                    We offer the
                    {' '}
                    <a
                      href="https://hourofcode.com/"
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        textDecoration: 'unset',
                        color: '#D8C096',
                      }}
                    >
                      hour of code
                    </a>
                    {' '}
                    to youngsters eager to learn the basics of
                    programming.
                  </Typography>
                </CardContent>
                <CardContent>
                  <SvgButton
                    onClick={() => window.open(
                      'https://www.eventbrite.com/e/hour-of-code-tickets-169286076211',
                      '_blank',
                    )}
                  >
                    <Typography variant="subtitle2" align="center">
                      Sign up today
                    </Typography>
                  </SvgButton>
                </CardContent>
              </Container>
            </div>
          </Grid>
        </Grid>
      </div>
    </SwipeableDrawer>
  );
}

export default DigitalLiteracy;
