import axonasLogo from '../../../images/axonas-services-logo.png';
import cernerLogo from '../../../images/cerner-logo.png';
import chastenLogo from '../../../images/chasten-logo-black.png';
import koelesLogo from '../../../images/koeles-logo.png';
import rofhiwaLogo from '../../../images/rofhiwa-logo.png';
import planeterraLogo from '../../../images/planeterra-logo.png';
import localCowboysLogo from '../../../images/local-cowboys-logo.png';
import livaDesignsStudioLogo from '../../../images/LDS-01.png';
import googleLogo from '../../../images/google-logo.png';
import githubLogo from '../../../images/githubLogo.png';
import workspaceLogo from '../../../images/workspaceLogo.png';
import slackLogo from '../../../images/slack-logo.png';

const partners = [
  {
    title: 'Axonas',
    name: 'Axonas Services',
    website: 'https://axonasservices.com',
    logo: axonasLogo,
  }, {
    title: 'Cerner',
    name: 'Cerner',
    website: 'https://www.cerner.com',
    logo: cernerLogo,
  },
  {
    title: 'Chasten Education Centre',
    name: 'Chasten Education Centre',
    website: 'http://chastenzm.com/',
    logo: chastenLogo,
  }, {
    title: 'KOELES GROUP',
    name: 'KOELES GROUP',
    website: 'https://koelesclothing.com',
    logo: koelesLogo,
  }, {
    title: 'Local Cowboy',
    name: 'Local Cowboy',
    website: 'https://localcowboy.com',
    logo: localCowboysLogo,
  }, {
    title: 'Rofhiwa',
    name: 'Rofhiwa',
    website: 'https://rofhiwabooks.com',
    logo: rofhiwaLogo,
  }, {
    title: 'Planeterra',
    name: 'Planeterra',
    website: 'https://planeterra.org',
    logo: planeterraLogo,
  }, {
    title: 'Liva Designs Studio',
    name: 'Liva Designs Studio',
    website: '',
    logo: livaDesignsStudioLogo,
  }, {
    title: 'Google',
    name: 'Google',
    website: '',
    logo: googleLogo,
  }, {
    title: 'Github',
    name: 'Github',
    website: '',
    logo: githubLogo,
  }, {
    title: 'Workspace Innovations',
    name: 'Workspace Innovations',
    website: '',
    logo: workspaceLogo,
  }, {
    title: 'Slack',
    name: 'Slack',
    website: '',
    logo: slackLogo,
  }
];

export default partners;
