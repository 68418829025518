import {
  CardContent,
  Container, Grid, IconButton, SwipeableDrawer, Toolbar, Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ConfettiExplosion from '@reonomy/react-confetti-explosion';
import { useThinkZambia } from '../context';
import SvgButton from './SvgButton';
import DonorBox from './DonorBox';

function DonateToThinkZambia() {
  const [isExploding, setIsExploding] = React.useState(false);

  const {
    donateDrawerOpen,
    setDonateDrawerOpen,
  } = useThinkZambia();

  useEffect(() => {
    if (donateDrawerOpen) {
      setIsExploding(true);
    }
  }, [donateDrawerOpen]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsExploding(false);
    }, 2900);
    return () => clearTimeout(timer);
  }, [isExploding]);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={donateDrawerOpen}
      onClose={() => setDonateDrawerOpen(false)}
      onOpen={() => setDonateDrawerOpen(true)}
      transitionDuration={{
        enter: 1000,
        exit: 300,
      }}
    >
      {isExploding && (
        <ConfettiExplosion
          floorHeight={1800}
          floorWidth={2000}
        />
      )}
      <div style={{
        width: '100vw',
        minHeight: '95vh',
        backgroundColor: '#364D61',
      }}
      >
        <Toolbar
          disableGutters
          style={{
            position: 'fixed',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            top: 'auto',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
          }}
        >
          <IconButton onClick={() => setDonateDrawerOpen(false)}>
            <KeyboardArrowDownIcon
              fontSize="large"
              style={{
                color: '#C7A567',
                fontSize: '4rem',
              }}
            />
          </IconButton>
        </Toolbar>
        <div style={{
          minHeight: '95vh',
          display: 'flex',
          placeContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'center',
        }}
        >
          <Container>
            <CardContent>
              <Typography
                variant="h1"
                style={{
                  color: '#ffffff',
                }}
              >
                Your donation matters
              </Typography>
              <Typography
                variant="subtitle1"
                color="secondary"
              >
                We rely on contributions like yours to keep
                the project going. Every dollar goes towards ensuring that our digital
                literacy and developer programs are running smoothly.
              </Typography>
            </CardContent>
          </Container>
        </div>
      </div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item sm={12}>
          <div style={{
            minHeight: '95vh',
            display: 'flex',
            placeContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            backgroundColor: '#f1f1f1',
          }}
          >
            <Toolbar />
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item sm={5}>
                <Container
                  maxWidth="sm"
                >
                  <CardContent>
                    <Typography variant="h2">
                      Join our mission
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="subtitle2">
                      Imagine a world where everyone has access to fundamentals in IT Education.
                      We believe digital literacy is a human right.
                      So we focus on providing opportunities for all youths to learn and
                      grow in technology. Together we seek to empower underrepresented
                      communities to enhance their lives through tech.
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="body2" color="secondary">
                      Think Zambia Foundation is a 501(c)(3) charitable organization,
                      EIN 82-3071147. All donations are deemed tax-deductible.
                      Click here to download our IRS confirmation letter.
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <SvgButton>
                      <Typography variant="subtitle2" align="center">
                        Annual Report
                      </Typography>
                    </SvgButton>
                  </CardContent>
                </Container>
              </Grid>
              <Grid
                item
                sm={6}
              >
                <Container>
                  <CardContent>
                    <DonorBox />
                  </CardContent>
                </Container>
              </Grid>
            </Grid>
            <Toolbar />
          </div>
        </Grid>
        <Grid item={12}>
          <div style={{
            minHeight: '95vh',
            display: 'flex',
            placeContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            backgroundColor: '#ffffff',
          }}
          >
            <Toolbar />
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item md={3} sm={12} xs={12}>
                <Container
                  maxWidth="sm"
                >
                  <CardContent>
                    <Typography variant="h2">
                      Create a
                    </Typography>
                    <Typography variant="h2">
                      Facebook
                    </Typography>
                    <Typography variant="h2">
                      Fundraiser
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="subtitle2">
                      We're verified! Which means you can create a fundraiser
                      for us at anytime on Facebook in just a few clicks.
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="subtitle2" color="secondary">
                      <a
                        href="https://www.facebook.com/fundraisers/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: 'unset',
                          color: '#D8C096',
                          cursor: 'pointer',
                        }}
                      >
                        Create a fundraiser now!
                      </a>
                    </Typography>
                  </CardContent>
                </Container>
              </Grid>
              <Grid item md={3} sm={12} xs={12}>
                <Container
                  maxWidth="sm"
                >
                  <CardContent>
                    <Typography variant="h2">
                      Follow
                    </Typography>
                    <Typography variant="h2">
                      us on
                    </Typography>
                    <Typography variant="h2">
                      Patreon
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="subtitle2">
                      Do you believe in our cause?
                      Become a sponsor of Think Zambia today to
                      receive exclusive insights on our journey.
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="subtitle2" color="secondary">
                      <a
                        href="https://www.patreon.com/thinkzambia"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: 'unset',
                          color: '#D8C096',
                          cursor: 'pointer',
                        }}
                      >
                        Become a Patreon
                      </a>
                    </Typography>
                  </CardContent>
                </Container>
              </Grid>
              <Grid item md={3} sm={12} xs={12}>
                <Container
                  maxWidth="sm"
                >
                  <CardContent>
                    <Typography variant="h2">
                      Setup
                    </Typography>
                    <Typography variant="h2">
                      Amazon
                    </Typography>
                    <Typography variant="h2">
                      Smile
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="subtitle2">
                      Did you know that amazon donates 0.5% of the price of eligible purchases
                      towards charity? It's easy to setup!
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="subtitle2" color="secondary">
                      <a
                        href="https://smile.amazon.com"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: 'unset',
                          color: '#D8C096',
                        }}
                      >
                        visit smile.amazon.com today
                      </a>
                    </Typography>
                  </CardContent>
                </Container>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Toolbar />
      </Grid>
    </SwipeableDrawer>
  );
}

export default DonateToThinkZambia;
