import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Iframe from 'react-iframe';

function DonorBox() {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item>
        <Paper
          elevation={6}
          style={{ padding: '1.5rem' }}
        >
          <Iframe
            align="center"
            allowpaymentrequest=""
            frameBorder="0"
            height="465"
            width="100%"
            name="donorbox"
            scrolling="yes"
            seamless="seamless"
            src="https://donorbox.org/embed/think-zambia-foundation-fund?default_interval=o"
          />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default DonorBox;
