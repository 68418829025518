import {
  CardContent,
  Container, IconButton, SwipeableDrawer, Toolbar, Typography,
} from '@mui/material';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useThinkZambia } from '../../context';
import SvgButton from '../SvgButton';
import Partner from '../Partner/Partner';

function TechnologyServices() {
  const {
    bottomDrawerOpen,
    setBottomDrawerOpen,
  } = useThinkZambia();

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={bottomDrawerOpen}
      onClose={() => setBottomDrawerOpen(false)}
      onOpen={() => setBottomDrawerOpen(true)}
      transitionDuration={{
        enter: 1000,
        exit: 300,
      }}
    >
      <div style={{
        width: '100vw',
        minHeight: '95vh',
        backgroundColor: '#111B2A',
      }}
      >
        <Toolbar
          disableGutters
          style={{
            position: 'fixed',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            top: 'auto',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
          }}
        >
          <IconButton onClick={() => setBottomDrawerOpen(false)}>
            <KeyboardArrowDownIcon
              fontSize="large"
              style={{
                color: '#C7A567',
                fontSize: '4rem',
              }}
            />
          </IconButton>
        </Toolbar>
        <div style={{
          minHeight: '95vh',
          display: 'flex',
          placeContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'center',
        }}
        >
          <Container>
            <CardContent>
              <Typography
                variant="h1"
                style={{
                  color: '#ffffff',
                }}
              >
                Have an idea?
              </Typography>
              <Typography
                variant="subtitle1"
                color="secondary"
              >
                Let's build it together! We offer exceptional technical services and solutions
                to match your needs. Become a technology partner today!
              </Typography>
            </CardContent>
            <CardContent>
              <div>
                <SvgButton
                  onClick={() => window.open(
                    'mailto:info@thinkzambia.org',
                    '_blank',
                  )}
                >
                  <Typography variant="subtitle2" align="center">
                    Email Us
                  </Typography>
                </SvgButton>
              </div>
            </CardContent>
          </Container>
        </div>
      </div>
      <div>
        <Partner />
      </div>
    </SwipeableDrawer>
  );
}

export default TechnologyServices;
