import React, { useEffect, useRef } from 'react';
import {
  Button,
  CardContent, Container, Grid, Grow, Hidden, Input, Toolbar, Typography,
} from '@mui/material';
import ReactImageGallery from 'react-image-gallery';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DigitalLiteracy from '../components/DigitalLiteracy';
import { ThinkZambiaProvider, useThinkZambia } from '../context';
import TechnologyServices from '../components/TechnologyServices';
import DeveloperProgram from '../components/DeveloperProgram';
import Layout from '../components/layout';
import SvgButton from '../components/SvgButton';
import DonateToThinkZambia from '../components/DonateToThinkZambia';
import AnimatedLogo from '../components/AnimatedLogo';
import Calendar from '../components/Calendar';
import Reports from '../components/Reports';
import ThinkZambiaFooter from '../components/ThinkZambiaFooter';

const images = [];

function IndexPageBase() {
  const indexPage = useRef(null);

  const {
    leftDrawerOpen,
    rightDrawerOpen,
    setLeftDrawerOpen,
    setBottomDrawerOpen,
    setRightDrawerOpen,
    setDonateDrawerOpen,
  } = useThinkZambia();

  function handleKeyDown(e) {
    if (e.key === 'ArrowLeft') {
      if (rightDrawerOpen) {
        setRightDrawerOpen(false);
      } else {
        setLeftDrawerOpen(!leftDrawerOpen);
      }
    }
    if (e.key === 'ArrowRight') {
      if (leftDrawerOpen) {
        setLeftDrawerOpen(false);
      } else {
        setRightDrawerOpen(!rightDrawerOpen);
      }
    }
  }

  useEffect(() => {
    indexPage.current.focus();
  }, []);

  return (
    <div
      ref={indexPage}
      tabIndex="0"
      onKeyDown={(e) => handleKeyDown(e)}
      role="button"
    >
      <Calendar />
      <DigitalLiteracy />
      <Reports />
      <TechnologyServices />
      <DeveloperProgram />
      <DonateToThinkZambia />
      <div style={{
        width: '100vw',
        height: '100vh',
        position: 'relative',
        top: 0,
        left: 0,
        zIndex: 1,
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
      }}
      >
        <Container>
          <Toolbar />
          <Grid
            container
            justifyContent="center"
            spacing={3}
          >
            <Grid item xs={12}>
              <AnimatedLogo />
            </Grid>
            <Grid item xs={12}>
              <Grow in timeout={1000}>
                <Container maxWidth="lg">
                  <Typography
                    align="center"
                    variant="subtitle1"
                  >
                    We are a nonprofit organization leading Zambia's digital revolution.
                  </Typography>
                  <Hidden only="xs">
                    <Typography
                      align="center"
                      variant="subtitle1"
                    >
                      Our three-step approach ensures everyone is empowered to
                      think and grow in technology.
                    </Typography>
                  </Hidden>
                </Container>
              </Grow>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Grow in timeout={1000}>
                <Grid
                  container
                  justifyContent="center"
                  spacing={0}
                >
                  <Grid item>
                    <SvgButton
                      onClick={() => setLeftDrawerOpen(true)}
                    >
                      <Typography variant="subtitle2" align="center">
                        Digital Literacy
                      </Typography>
                    </SvgButton>
                  </Grid>
                  <Grid item>
                    <SvgButton
                      onClick={() => setBottomDrawerOpen(true)}
                    >
                      <Typography variant="subtitle2" align="center">
                        Technology Services
                      </Typography>
                    </SvgButton>
                  </Grid>
                  <Grid item>
                    <SvgButton
                      size="large"
                      onClick={() => setRightDrawerOpen(true)}
                    >
                      <Typography variant="subtitle2" align="center">
                        Developer Program
                      </Typography>
                    </SvgButton>
                  </Grid>
                </Grid>
              </Grow>
            </Grid>
            <Grid item xs={12}>
              <CardContent>
                <Typography
                  align="center"
                  variant="body2"
                  color="secondary"
                >
                  click a button to learn more about our work.
                </Typography>
              </CardContent>
              <div
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  size="large"
                  onClick={() => setDonateDrawerOpen(true)}
                  startIcon={<FavoriteIcon color="secondary" />}
                  style={{
                    borderRadius: '0',
                    padding: '1em 2.5em',
                    textTransform: 'unset',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    align="center"
                    color="secondary"
                  >
                    Donate Now
                  </Typography>
                </Button>
              </div>
            </Grid>
          </Grid>
          <Toolbar />
          <Toolbar />
        </Container>
      </div>
      <div style={{
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 0,
        overflow: 'hidden',
      }}
      >
        <ReactImageGallery
          items={images}
          showThumbnails={false}
          showPlayButton={false}
        />
      </div>
    </div>
  );
}

export default function IndexPage() {
  return (
    <ThinkZambiaProvider>
      <Layout>
        <IndexPageBase />
        <ThinkZambiaFooter />
      </Layout>
    </ThinkZambiaProvider>
  );
}
