import {
  CardContent,
  Container,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from '@mui/material';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useThinkZambia } from '../../context';

import ThinkZambiaAnnualReport2020 from '../../downloads/Think-Zambia-Annual-Report-2020.pdf';
import ThinkZambiaAnnualReport2021 from '../../downloads/Think-Zambia-Annual-Report-2021.pdf';

const reports = [
  {
    id: 2020,
    text: 'Annual Report 2020',
    url: ThinkZambiaAnnualReport2020,
  },
  {
    id: 2021,
    text: 'Annual Report 2021',
    url: ThinkZambiaAnnualReport2021,
  },
];

export default function Reports() {
  const {
    reportsDrawerOpen,
    setReportsDrawerOpen,
  } = useThinkZambia();

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={reportsDrawerOpen}
      onClose={() => setReportsDrawerOpen(false)}
      onOpen={() => setReportsDrawerOpen(true)}
      transitionDuration={{
        enter: 1000,
        exit: 300,
      }}
    >
      <div
        style={{
          width: '100vw',
          minHeight: '95vh',
          backgroundColor: '#364D61',
        }}
      >
        <Toolbar
          disableGutters
          style={{
            position: 'fixed',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            top: 'auto',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
          }}
        >
          <IconButton onClick={() => setReportsDrawerOpen(false)}>
            <KeyboardArrowDownIcon
              fontSize="large"
              style={{
                color: '#C7A567',
                fontSize: '4rem',
              }}
            />
          </IconButton>
        </Toolbar>
        <div style={{
          height: '100vh',
          display: 'flex',
          placeContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'center',
        }}
        >
          <Container>
            <CardContent>
              <Typography
                variant="h1"
                style={{
                  color: '#ffffff',
                }}
              >
                Annual Reports
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="subtitle1" color="secondary">
                We are committed to providing information on our projects, goals &
                fundraising efforts. Our annual reports outline fundraising, operations,
                marketing & social media, and projects at Think Zambia.
              </Typography>
            </CardContent>
          </Container>
        </div>
      </div>
      <div
        style={{
          width: '100vw',
          minHeight: '95vh',
        }}
      >
        <Container>
          <CardContent>
            <Toolbar />
            <Toolbar />
            <Grid container spacing={1}>
              {reports.map((report) => (
                <Grid key={report.id} item xs={12}>
                  <ListItem
                    button
                    component="a"
                    href={report.url}
                    download
                  >
                    <ListItemIcon>
                      <FileDownloadIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="body2">
                        {report.text}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Container>
      </div>
    </SwipeableDrawer>
  );
}
