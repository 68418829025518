import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import {
  Card, CardContent, CardMedia, Container, Toolbar, Typography,
} from '@mui/material';
import data from './Partners';
import SvgButton from '../SvgButton';

export default function Partner() {
  const [partners, setPartners] = useState();

  useEffect(() => {
    setPartners(data);
  }, [data]);

  return (
    <div style={{
      minHeight: '95vh',
      display: 'flex',
      placeContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      backgroundColor: '#f1f1f1',
    }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item md={5}>
          <Toolbar />
          <Container
            maxWidth="sm"
          >
            <CardContent>
              <Typography variant="h2">
                Coorporate Social Responsibility
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="subtitle2">
                We are committed to improving the community by embracing technology.
                Click below to find out how you can get your idea implemented as
                part of Think Zambia's developer program! Become a technology partner today!
              </Typography>
            </CardContent>
            <CardContent>
              <SvgButton
                onClick={() => window.open(
                  'mailto:info@thinkzambia.org',
                  '_blank',
                )}
              >
                <Typography variant="subtitle2" align="center">
                  Email Us
                </Typography>
              </SvgButton>
            </CardContent>
          </Container>
          <Toolbar />
        </Grid>
        <Grid
          item
          md={6}
        >
          <Container
            style={{
              backgroundColor: 'white',
            }}
          >
            <Toolbar />
            <CardContent>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={5}
              >
                { partners && partners.map((partner) => (
                  <Grid
                    key={partner.id}
                    item
                    sm={3}
                    xs={4}
                    align="center"
                  >
                    <Card square elevation={0}>
                      <CardMedia
                        component="img"
                        image={partner.logo}
                        alt={partner.name}
                      />
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
            <Toolbar />
          </Container>
        </Grid>
      </Grid>
    </div>
  );
}
