import React from 'react';
import { GoogleCalendarEvents } from '@think-zambia-foundation/shared-gatsby/components';
import { SwipeableDrawer, Toolbar } from '@mui/material';
import { useThinkZambia } from '../../context';

export default function Calendar() {
  const {
    calendarDrawerOpen,
    setCalendarDrawerOpen,
  } = useThinkZambia();

  return (
    <SwipeableDrawer
      anchor="top"
      open={calendarDrawerOpen}
      onClose={() => setCalendarDrawerOpen(false)}
      onOpen={() => setCalendarDrawerOpen(true)}
      transitionDuration={{
        enter: 1000,
        exit: 300,
      }}
    >
      <div style={{ backgroundColor: '#f1f1f1' }}>
        <Toolbar />
        <GoogleCalendarEvents
          numResults={3}
          calendarId="c_21joia2js3erl43cglkgp0gdt4@group.calendar.google.com"
          API_KEY="AIzaSyD9VdBNt6weNVu0L4lOCucRK6oN1_-1_-I"
          CLIENT_ID="725149588399-6p6jn5267eb1j7cur5k1j3959bv5halu.apps.googleusercontent.com"
        />
        <Toolbar />
      </div>
    </SwipeableDrawer>
  );
}
